.Toolbar-loader {
  margin: 3px;
}

.Toolbar-loader form {
  display: inline;
}

.Toolbar-loader input {
  margin-left: 3px;
  margin-right: 3px;
}

.Toolbar-loader button {
  margin-right: 3px;
}

.Toolbar-controller button {
  margin-left: 3px;
}

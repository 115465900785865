.GitHubCorner-container:hover .GitHubCorner-octo-arm {
  animation: GitHubCorner-octocat-wave 560ms ease-in-out;
}

@keyframes GitHubCorner-octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .GitHubCorner-container:hover .GitHubCorner-octo-arm {
    animation: none;
  }
  .GitHubCorner-octo-arm {
    animation: GitHubCorner-octocat-wave 560ms ease-in-out;
  }
}

@media (max-width: 300px) {
  .GitHubCorner-container {
    display: none;
  }
}
